/*States Form*/
#loading-login {
    display: none;
    margin: 1.2rem auto 0 auto;
}

#loading-submit,
#loading-elem {
    margin-top: .8rem;
    margin-left: auto;
    margin-right: auto;
}

.notice{
    display: block;
    margin: .8rem 0;
    padding: 0.8rem;
    background-color: #f2f1f1;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 8px;
    letter-spacing: 0.01em;
}

.notice.error{
    background-color: #ffdbe2;
    color: #f22e5b;
    animation: horizontal-shaking 0.25s ease-in-out;
}

.notice.error::before{
    content: '\26A0';
    display: inline;
    padding-right: .8rem;
    font-size: 1.2em;
    line-height: 1;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}


/*Temp css*/
.forgot-password{display: none;}


.form-window {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    height: 100vh;
}

.form-window .page-login{
    display: block;
    position: absolute;
    width: 100%;
    max-width: 480px;
    z-index: 99;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
    max-height: calc(100vh - 5rem);
    overflow-y: auto;
    top: 6rem;
    left: calc(50% - 240px);
    min-height: 250px;
}

.form_title{
    font-family: 'Playfair Display', serif;
    font-style: normal;
}

.form-window .btn{
    display: block;
    width: 100%;
    background: var(--orange)!important;
    color: #fff;
    font-weight: 600;
    padding: 1.4rem 2.4rem;
}

.icon-close{
    cursor: pointer;
}

.form-window .icon-close{
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    z-index: 10;
}

.form-window .icon-user{
    display: block;
    margin: 0.8rem auto 1.6rem auto;
}

/*TextField*/
.form-window label[for=email],
.form-window label[for=password]{
    display: none;
}

.form-window input,
.form-window input{
    padding-left: 4rem;
    box-sizing: border-box;
}

.form-window .icon-field{
    position: absolute;
    left: 0;
    top: .6em;
}

.form-window .form-group{
    position: relative;
}

.form-window .show-password-label{
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.form-window .show-password-label input{
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin: 0 .8rem 0 0;
}