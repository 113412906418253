@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,700;1,700&family=Poppins:ital,wght@0,300;0,700;1,300;1,700&display=swap');

:root{
  --orange: #E4441C;
  --black: #1a1a1a;
  --color-error-500:#f22e5b;
  --color-error-100:#ffdbe2;
  --gradient: linear-gradient(-90deg, #E4441C 0%, #C21F20 100%), #E4441C;
  --swiper-theme-color: #C21F20!important;
  --toastify-toast-width: 380px!important;
}

.no-iubenda .iubenda-tp-btn,
.no-iubenda .iub__us-widget,
.iubenda-tp-btn:not([data-tp-nostyle]){
   display: none!important;
}

/***
  Html Reset
***/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

button{
  background: unset;
  cursor: pointer;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  position: relative;
}

img{
  max-width: 100%;
}

p em,
h1 em,
h2 em,
h3 em,
h4 em,
h5 em,
h6 em{
  font-family: 'Playfair Display', serif;
  font-size: 1.1em;
}

.entry-content ul {
  margin-left: 4rem;
  margin-bottom: 2em;
}

.entry-content ul li{
  margin-bottom: .5em;
}

.entry-content ul li::before {
  content: "\2022"; 
  color: var(--orange); 
  font-weight: bolder;
  display: inline-block; 
  width: 1.2em; 
  margin-left: -1.2em;
}

.entry-content p {
  margin-bottom: 1.1em;
  line-height: 1.6;
  max-width: 60rem;
  letter-spacing: 0.025em;
}


/***
  Paddings & Margins
***/
.space{
  display: block;
  overflow: hidden;
  clear: both;
}

.pl-4{
  padding-left: 3.2rem;
}

.px-4{
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.px-3{
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.py-7{
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
}

.py-4{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-3{
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.py-2{
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.m0{
  margin: 0!important;
}

.mb-2{
  margin-bottom: 1.2rem;
}

.mb-5{
  margin-bottom: 4rem;
}

.mb-7{
  margin-bottom: 7.2rem;
}

.mb-8{
  margin-bottom: 8rem;
}


/***
  Borders
***/
.border-t{
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-top: 2.4rem;
}

.bg-gradient .border-t{
  border-color: rgba(255, 255, 255, 0.5);
}


/***
  Grids
***/
.max-w-site{
  overflow: hidden;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  width: 100%;
}

.grid{
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}

.grid.size_6-6 {
  grid-template-columns: 1fr 1fr;
}

.grid.size_6-5 {
  grid-template-columns: 1fr .9fr;
  grid-column-gap: 15%
}

.grid.size_5-6 {
  grid-template-columns: .9fr 1fr;
  grid-column-gap: 15%
}

.grid.landing_size_5-6 {
  grid-template-columns: .9fr 1fr;
  grid-column-gap: 1%
}
/***
  Added by Salvo for landing page

.grid.size_3-3-3 {
    grid-template-columns: repeat(3, 1fr);
}
***/
.grid.size_3-3-3-3 {
  grid-template-columns: repeat(4, 1fr);
}

.align-center{
  align-items: center;
}

.float-r{
  float: right;
  clear: both;
}


/***
  Blocks
***/
.image-divider{
  position: relative;
}

.image-divider::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}

.inner-image-divider{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: antiquewhite;
}

.inner-image-divider > div{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/***
  Colors
***/
.bg-gradient{
  background: var(--gradient);
  color: #fff;
}

.orange{
  color: var(--orange);
}

.white{
  color: #fff;
}


/***
  Fonts
***/
h1, .h1{
  font-size: 4.4rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: .5em;
}

h2, .h2{
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: .8em;
}

h3, .h3{
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: .8em;
}

h4, .h4{
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: .8em;
}

.t-align-center{
  text-align: center;
}

.p-small{
  font-size: 1.45rem;
  letter-spacing: 0.01em;
}

.p-xsmall{
  font-size: 1.25rem;
  letter-spacing: 0.01em;
}

.p-medium{
  font-size: 1.65rem;
  letter-spacing: 0.01em;
}

.p-large{
  font-size: 2.85rem;
  letter-spacing: 0.01em;
}

.landing-important{
  min-height: 0px !important;
}
/***
  Input & Form
***/
label, input, textarea, select{
  display: block;
  width: 100%;
  overflow: hidden;
}

label{
  margin-bottom: .4rem;
  opacity: .75;
}

label .required{
  color:var(--orange);
}

input, textarea, select{
  border:none;
  border-bottom: 1px solid #000;
  padding: .8rem;
  outline: none;
  color: var(--orange);
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
}

.form-group{
  margin-bottom: 2.8rem;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}

.form_title{
  margin-bottom: 4rem;
}

.form-group button#Contract,
.form-group button#Semplice{
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  border: 1px solid;
  border-color: inherit;
  border-radius: 99px;
  padding: 1rem 3rem;
  letter-spacing: 0;
  margin-right: 1rem;
  margin-top: .5rem;
  font-size: 1.4rem;
  color: var(--orange);
}

.form-group button#Contract.selected,
.form-group button#Semplice.selected{
  background-color: var(--orange);
  color: #fff;
}

.form-group .error-message{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
  color: #C21F20;
  letter-spacing: 0.05em;
  padding-top: .5rem;
}




/***
  Buttons
***/
.btn{
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  border: 1px solid;
  border-color: inherit;
  border-radius: 99px;
  padding: 1.2rem 3.6rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.white.btn{
  border-color: #fff;
}

.orange.btn{
  border-color: var(--orange);
}

.btn::before{
  content: '';
  display: block;
  width: .7em;
  height: .7em;
  border-radius: 99px;
  position: absolute;
  top: calc(50% - .35em);
  left: 1.6rem;
  z-index: -1;

  transition: all .3s ease;
}

.white.btn::before{
  background-color: #fff;
}

.orange.btn::before{
  background-color: var(--orange);
}

/*Hover*/
.white.btn:hover{
  color: var(--orange);
}

.orange.btn:hover{
  color: #fff;
}

.btn:hover::before{
  transform: scale(50);
  transition: all .3s cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

#loading-container {
  display: none;
  float: right;
  margin: 0 16px;
}

#submit:disabled,
#submit[disabled]{
  opacity: 0.4;
}



/*Tel Shortcut*/
.shortcut-tel{
  display: none!important;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background-color: var(--orange);
  width: 5.2rem;
  height: 5.2rem;
  z-index: 80;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  box-shadow: 0 0 38px #00000045;
  opacity: 0;
  transition: opacity .3s ease;
}

.shortcut-tel img{
  filter: brightness(10);
  animation: tilt-shaking 3s infinite ease-in-out;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }

  18% { transform: rotate(0deg); }
  20% { transform: rotate(-10deg); }
  22% { transform: rotate(0deg); }
  24% { transform: rotate(10deg); }
  26% { transform: rotate(0deg); }
  28% { transform: rotate(-10deg); }
  30% { transform: rotate(0deg); }

  100% { transform: rotate(0deg); }
}

@media screen and (max-width: 769px) {
  .shortcut-tel{
    display: flex!important;
  }

  .shrink ~ .shortcut-tel{
    opacity: 1;
    transition: opacity .3s ease;
  }
}