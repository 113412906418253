.widget-contract_cont {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.widget-contract_cont p{
    font-size: 1.8rem;
    font-weight: 300;
}

.site-footer{
    background-color: #000;
    color: #fff;
    font-size: 1.4rem;
    margin-top: 12rem;
}


.landing-footer{
    background-color: #000;
    color: #fff;
    font-size: 1.4rem;
    margin-top: 0rem;
}

.cont-footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 6rem;
    padding-bottom: 8rem;
}

.footer_col-3{
    width: calc((100% / 12) * 3);
}

.footer_col-2{
    width: calc((100% / 12) * 2);
}

.footer_col-2,
.footer_col-3{
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 4rem;
}

.footer_logo{
    margin-bottom: 4.8rem;
}

.footer_label-info{
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
    color: var(--orange);
}

.footer_label-text{
    margin-bottom: 2.4rem;
    line-height: 1.2;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.footer_label-text .icon{
    min-width: 3.2rem;
}

.footer_label-text img{
    vertical-align: middle;
}

.footer-nav a{
    color: #fff;
    text-decoration: none;
}

.footer-nav .item-nav{
    margin-bottom: .8rem;
}

.footer-nav a:hover{
    opacity: 0.5;
}

.footer-nav.secondary,
.footer-nav.secondary a{
    font-size: 1.1rem;
    color: #808080;
}


@media screen and (max-width: 1024px) {
    .footer_col-3,
    .footer_col-2 {
        width: calc(50% - 1.6rem);
    }

    .footer_col-2{
        margin-top: 4rem;
    }

    .cont-footer{
        gap: 1.6rem;
    }

    .widget-contract_cont .btn{
        min-width: 20rem;
    }
}

@media screen and (max-width: 769px) {
    .widget-contract_cont{
        flex-direction: column;
        text-align: center;
    }

    .widget-contract_cont.py-2{
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    .widget-contract_cont p{
        margin-bottom: 2.4rem;
    }
}

@media screen and (max-width: 600px) {
    .footer_col-3, .footer_col-2 {
        width: 100%;
        padding-top: 2rem;
    }

    .footer_col-3{
        border-top: none;
    }

    .footer_col-2 {
        margin-top: 2rem;
    }

    .cont-footer{
        padding-top: 4rem;
        padding-bottom: 6rem;
    }

    .site-footer{
        margin-top: 7.2rem;
    }

    .landing-footer{
        margin-top: 7.2rem;
    }

}