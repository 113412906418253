/***
CSS Navbar
***/
.site-header{
    background-color: rgba(255,255,255, 0.96);
    z-index: 90;
    position: relative;
}

.site-header button,
.topbar button{
    padding: 0;
    border:none;
}

.cont-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.inner-nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.inner-nav li{
    margin-right: 3.2rem;
    line-height: 0;
}

.inner-nav li:last-child{
    margin-right: 0;
}

.inner-nav li a:not(.btn){
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color:#fff;
}

.inner-nav li a.btn{
    font-size: 1.4rem;
}

.inner-nav li a:hover:not(.btn){
    opacity: 0.5;
}


/* If page = home */
.is-home.site-header{
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}

.is-home.site-header.bg-gradient{
    background: transparent;
}

/* Fixed navbar on scroll */
.site-header.shrink{
    z-index: 90;
    position: sticky;
    top: 0;
    left: 0;
    -webkit-animation: slide-in-top 0.4s both;
	        animation: slide-in-top 0.4s both;
    box-shadow: 0 2px 12px 0px #00000014;
}
@-webkit-keyframes slide-in-top {
0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
}100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}}
@keyframes slide-in-top {
0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
}100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}}

.site-header.shrink .inner-nav li a:not(.btn){
    color: black;
}

.site-header.shrink .site-logo{
    background: url(../../img/logo-positivo.svg) no-repeat;
    background-size: contain;
    max-width: 14rem;
    background-position: center center;
}

.site-header.shrink .site-logo img{
    opacity: 0;
}

.site-header.shrink .cont-header.py-2{
    padding-top: 1rem;
    padding-bottom: 1rem;
}


/***
 Hamburger menu
***/
.cont-hamburger{
    display: none;
    pointer-events: none;
    position: relative;
    z-index: 91;
}


/***
Top bar
***/
.topbar{
    background-color: var(--black);
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.topbar a{
    color: #fff;
    text-decoration: unset;
    display: inline-block;
    line-height: 1;
}

.topbar a:hover{
    opacity: 0.5;
}

.topbar__right .topbar__right__cont{ 
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.cta-login img{
    vertical-align: middle;
    display: inline-block;
    padding-right: 4px;
}

.cta-login{
    margin-left: 2.4rem;
    padding-left: 2.4rem;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.topbar__left img{
    vertical-align: sub;
    line-height: 1;
    padding-right: .8rem;
}


/* Lang */
.cont-lang{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.cont-lang img{
    margin: 0 .4rem;
}

.cont-lang button {
    color: #fff;
    font-size: 1.2rem;
    line-height: 1;
    padding-bottom: .25rem;
    border-bottom: 0px solid;
    min-width: 2.4rem;
}

.cont-lang button:hover {
    opacity: 0.4;
}

.cont-lang button:first-child {padding-right: 1rem;}
.cont-lang button:last-child {padding-left: 1rem;}

.cont-lang.it-selected > button:last-child,
.cont-lang.en-selected > button:first-child{
    font-weight: bold;
    border-bottom: 1px solid;
}



/***
Responsive
***/

@media screen and (max-width: 1099px) {
    .site-logo{
        position: relative;
        z-index: 1;
    }

    .cont-hamburger{
        display: block;
        pointer-events: auto;
    }

    .menu-open{
        overflow: hidden;
    }

    .cont-nav{
        position: absolute;
        background: var(--gradient);
        top: 0;
        right: 0;
        width: 0;
        max-width: 480px;
        height: 100vh;
        z-index: 0;
        display: block;
        overflow: hidden;
        transition: width .3s ease-out;
    }

    .menu-open .cont-nav{
        width: 100%;
        transition: width .5s ease-in-out;
    }

    .menu-open .site-header::before{
        content: '';
        display: block;
        background: #000;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.33;
    }

    .cont-nav .inner-nav{display: none;}

    .inner-nav li{margin-right: 0;}

    .menu-open .cont-nav .inner-nav{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;    
        justify-content: flex-start;
        align-items: center;
        padding: 12rem 3rem 2rem 3rem;
        text-align: center;
        max-height: 100vh;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .menu-open .cont-nav .inner-nav li{
        margin-bottom: 4rem;
    }

    .menu-open .cont-nav .inner-nav li a{
        font-size: 1.6rem;
    }


    .menu-open .cont-nav{
        display: block;
    }

    .shrink .hamburger-react div{
        background: #000!important;
    }

    .hamb-menu{
        display: block;
    }

    .menu-open .shrink .hamburger-react div{
        background: #fff!important;
    }

    .menu-open .site-header.shrink .inner-nav li a{
        color: #fff;
    }

    .menu-open .site-header.shrink .inner-nav li a.orange.btn{
        border-color: #fff;
    }

    .menu-open .site-header.shrink .inner-nav li a.orange.btn::before{
        background-color: #fff;
    }

    .menu-open .site-header.shrink .inner-nav li a.orange.btn:hover{
        color: var(--orange);
    }
}

@media screen and (max-width: 769px) {
    .cont-nav{
        max-width: 100%;
    }

    .menu-open .site-header.shrink .site-logo{
        background: unset;
    }

    .menu-open .site-header.shrink .site-logo img {
        opacity: 1;
    }
    
    .menu-open .cont-nav .inner-nav li a {
        font-size: 1.8rem;
    }

    .menu-open .cont-nav .inner-nav li.item-nav,
    .menu-open .cont-nav .inner-nav li.item-nav a:not(.btn){
        width: 100%;
    }

    .topbar{
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }

    .topbar__left{
        display: none;
    }

    .topbar__right{
        width: 100%;
    }

    .topbar__right .topbar__right__cont {
        justify-content: space-between;
    }

    .cta-login {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
    }
}

@media screen and (max-width: 600px) {
    .site-logo a{line-height: 1; display: block;}
}