/*Variables*/
:root{
    --dark-bkg: #1a1a1a;
    --light-bkg: #f5f5f5;
    --extra-light-bkg: #fff;
    --accent-color: #E4441c;
    --accent-color-200: rgba(228, 68, 28, 0.1);

    --secondary-color: #1a73e8;
    --secondary-color-200: rgba(26, 115, 232, 0.1);
}

.display-none{
    display: none;
}

.private-content *{
    box-sizing: border-box;
}

/*Top Bar*/
.private-topbar, .private-topbar.topbar{
    color: #fff;
    padding-top: 0;
    padding-bottom: 1.2rem;
    display: flex;
}

.private-topbar .backtosite{
    padding:1rem 3.2rem 1rem 0;
    margin-right: 3.2rem;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 1.4rem;
}

.private-topbar .topbar__right{
    display: flex;
    flex-direction: row;
    align-items: center;
}


/*Main Grid layout*/
.private-content{
    background:var(--dark-bkg);
    display: block;
    overflow: hidden;
    padding: 1.2rem 2.4rem;
    min-height: 100vh;
    position: relative;
}

.private-inner{
    background-color: var(--light-bkg);
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem;
    border-radius: 2.4rem;
    min-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: stretch;
}


/*Sidebar*/
.private-inner > .private-sidebar{
    width: 25%;
    max-width: 300px;
    min-width: 220px;
    overflow: hidden;
}

.cont-private-sidebar{
    padding: 1.6rem 3.8rem 3.2rem 0.8rem;
    height: 100%;
    top: 0;
}

.inner-private-sidebar{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
}

.cont-private-sidebar.shrink{
    position: fixed;
    transition: .3s all ease;
    top: 0px;
    width: calc(25% - 3rem);
    max-width: 300px;
    min-width: 220px;
}

.private-nav{
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

.private-nav .item-nav a,
.private-sidebar .item-nav a{
    margin-bottom: 1.2rem;
    padding: 1.4rem 1.6rem;
    cursor: pointer;
}

.private-nav .item-nav:last-child a{
    margin-bottom: 0;
}

.private-sidebar .item-nav a{
    color: var(--dark-bkg);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.15px;
    opacity: .8;
    text-decoration: none;
    display: block;
}

.private-nav .item-nav a.active{
    border-radius: 12px;
    background:rgba(228, 68, 28, 0.1);
    cursor: auto;
    color: var(--accent-color);
    position: relative;
}

/*.private-nav .item-nav a.active::after {
    border-style: solid;
    border-width: 0.14em 0.14em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    position: absolute;
    top: 1.25em;
    right: 2rem;
    transform: rotate(45deg);
    vertical-align: top;
    width: 0.45em;
}*/

.private-nav .item-nav .active{
    color: var(--accent-color);
    font-weight: bold;
}

.item-nav img{
    vertical-align: text-top;
    margin-right: 1rem;
}

.item-nav .active img{
    filter: invert(68%) sepia(54%) saturate(5698%) hue-rotate(331deg) brightness(100%) contrast(84%);
}

.private-nav{
    width: 100%;
}

.private-username{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 1.4rem;
    overflow: hidden;
    font-weight: bold;
    color: var(--dark-bkg);
    line-break: anywhere;
    line-height: 1.1;
}

.private-username img{
    width: 5.2rem;
    margin-right: 1.2rem;
    height: auto;
    border-radius: 999px;
}


/*Main Content pages*/
.private-inner > .private-page{
    width: 80%;
    overflow: hidden;
    background-color: var(--extra-light-bkg);
    border-radius: 20px;
    box-shadow: 0 0 8px -5px #0000001c;
    padding: 2.2rem 2.2rem 3.2rem 2.8rem;
    box-sizing: border-box;
}

/*Tables*/
.private-table{
    width: 100%;
    text-align: left;
}

.private-table thead{
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.01em;
    height: 2rem;
}

.private-table th{
    position: sticky;
    top: -1px;
    padding: 1.8rem 1rem;
}

.private-table td{
    font-size: 1.4rem;
    line-height: 1.2;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid rgb(236, 236, 236);
    padding: 1.8rem 1rem;
    opacity: 0.8;
}

.private-table td.quote,
.private-table td.action{
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.private-table tr:last-child td{
    border-bottom: unset;
}

.private-table tbody tr:hover{
    background-color: var(--light-bkg);
    transition: .2s all ease;
}

.private-table tbody tr{
    transition: .2s all ease;
    border-radius: 1.2rem;
    overflow: hidden; 
}

.private-table tbody tr .checked-submit-design{
    display: none;
    background-color: var(--secondary-color);
}
.private-table tbody tr.submitted-row .checked-submit-design{
    display: inline-block;
    background-color: var(--secondary-color);
}

.private-table tbody tr .submit-design{
    display: inline-block;
}
.private-table tbody tr.submitted-row .submit-design{
    display: none;
}

.private-table tbody tr.submitted-row{
    background-color: var(--secondary-color-200);
}

.private-table .table-cell-action button{
    border: none;
}

.private-table .table-cell-action.action{
    width: 8rem;
}

.private-table.has-row-link tbody tr{
    cursor: pointer;
}

.private-table.has-row-link tbody tr td:last-child:after {
    border-style: solid;
    border-width: 0.14em 0.14em 0 0;
    border-color: var(--accent-color);
    content: '';
    display: inline-block;
    height: 0.45em;
    top: 1.25em;
    right: 2rem;
    transform: rotate(45deg);
    width: 0.45em;
}

.private-table .inline-loader{
    vertical-align: middle;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.private-table .inline-loader img{
    width: 2.4rem;
    margin-right: .6rem;
}


/*Row Actions*/
.private-page .row-actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
}

.private-page.private-page.negotiation-details .row-actions{
    align-items: flex-start;
}


/*Search*/
.private-page .search-field{
    width: 50%;
    min-width: 320px;
    position: relative;
}

.private-page .search-field input{
    background-color: var(--light-bkg);
    width: 100%;
    display: block;
    border: none;
    font-size: 1.4rem;
    padding: 1.2rem 1.2rem 1.2rem 4.8rem;
    border-radius: 999px;
}

.private-page .search-field img{
    display: block;
    position: absolute;
    left: 11px;
    top: 11px;
    z-index: 2;
    opacity: 0.5;
}


/*Btn*/
.private-page .btn::before{
    display: none;
}

.private-page button,
.private-page .btn{
    font-family: inherit;
    font-weight: 700;
    letter-spacing: 0.015em;
    background-color: var(--accent-color);
    color: var(--extra-light-bkg);
    transition: .3s all ease;
    border-radius: 999px;
    border: none;
    outline: none;
    text-transform: uppercase;
}

.private-page .button:hover,
.private-page .btn:hover{
    box-shadow:300px 0 300px 0 rgba(0,0,0,0.1) inset;
    transition: .3s all ease;
}

.private-page button.icon-button-round{
    border-radius: 999px;
    overflow: hidden;
    line-height: 0;
    background: var(--light-bkg);
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 1.2rem;
    cursor: pointer;
    transition: all .2s ease;
    padding: 0;
}

.private-page button.icon-button-round:hover{
    background: rgba(228, 68, 28, 0.1);
    transition: all .2s ease;
}

.private-page button.icon-button-round img{
    padding: 0.4rem;
}

.private-page button.icon-button-round:disabled{
    background: none;
    cursor:not-allowed;
}

.private-page button.icon-button-round:disabled img{
    filter: grayscale(1);
    opacity: 0.3;
}

.private-page .btn.has-left-icon{
    padding-left: 2rem;
    line-height: 1;
}

.private-page .btn.has-left-icon img{
    margin-right: .8rem;
    vertical-align: text-bottom;
    display: inline-block;
}



/*Large Square button*/
.large-button{
    background-color: var(--light-bkg);
    border-radius: 1.6rem;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 2.4rem;
    height: 14rem;
    width: 20rem;
    transition: .3s all ease;
    overflow: hidden;
    margin-left: 1.6rem;
    text-decoration: none;
    position: relative;
}

.large-button:hover{
    background-color: rgba(228, 68, 28, 0.1);
    transition: .25s all ease;
}

.large-button .large-button_icon{
    width: 4rem;
    height: auto;
    margin-bottom: 0.4rem;
}

.large-button .large-button_text{
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--accent-color);
    letter-spacing: 0.02em;
    margin-top: 1.6rem;
}

.large-button .custom img{
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: var(--extra-light-bkg);
    padding: .8rem;
    border-bottom-left-radius: 12px;
    display: block;
    line-height: 0;
    height: auto;
}

.private-page button.go-back{
    background-color: unset;
    padding: 0;
    color: var(--orange);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    text-transform: none;
    margin-bottom: 2rem;
    margin-top: -0.8rem;
}



/*Toast*/
.private-page .toast_onpage{
    background-color: var(--secondary-color-200);
    border:1px solid var(--secondary-color-200);
    border-radius: .8rem;
} 

.private-page .toast_onpage:hover{
    border-color: var(--extra-light-bkg);
}

.private-page .toast_onpage.small{
    font-size: 1.2rem;
    padding: .5rem 1.2rem .5rem 0.2rem;
    line-height: 1;
    color: var(--dark-bkg);
    text-transform: unset;
    font-weight: normal;
}

.private-page .toast_onpage span{
    text-decoration: underline;
    color: var(--accent-color);
    font-weight: 600;
    margin-left: .4rem;
}

.private-page .toast_onpage img{
    opacity: 0.75;
    margin-right: .6rem;
    margin-left: .6rem;
    width: 1.8rem;
    vertical-align: middle;
}



/*Fix single page - negotiations*/
.table-cell-action{
    text-align: right;
}

.cell-id{
    width: 3rem;
    opacity: .35!important;
    padding-left: 0!important;
}

.table-cell-action.negotiations button,
.table-cell-action button{
    margin-right: .8rem;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    padding: .5rem 1.4rem;
    font-size: 1.2rem;
    background-color: unset;
    opacity: 1;
}

.table-cell-action.negotiations a{
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-left: .8rem;
    padding-left: 1.6rem;
    border-left: 1px solid rgb(224, 224, 224);
    color: var(--accent-color);
    text-decoration: unset;
    vertical-align: middle;
}


/*Fix single page - Details negotiation*/
.info-negotiation td{
    padding-right: 1rem;
}

.info-negotiation tr td{
    font-size: 0.85em;
}

.info-negotiation tr td:first-child{
    opacity: 0.5;
}

.private-page.negotiation-details .row-actions{
    margin-bottom: 6.4rem;
}

.header-quotations{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.header-quotations .toast_onpage{
    margin-left: 1.6rem;
}

.table-cell-action .icon-button-round{
    border:none
}

.private-page .table-cell-action button.icon-button-round img{
    padding: 0.8rem;
    display: block;
    box-sizing: content-box;
    width: 2.2rem;
    height: auto;
}

.status-indicator{
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: baseline;
    background-color: var(--light-bkg);
    border-radius: 99px;
    margin-right: .4rem;
}

.doc-avaible.status-indicator{
    background-color: green;
}

.doc-notavaible.status-indicator{
    background-color: goldenrod;
}



/*Form window, input*/
.private-page .form-window input{
    padding-left: 0;
}

.private-page .form-window .form-group label{
    font-size: 1.2rem;
    font-weight: bold;
    opacity: 0.8;
}

.private-page .form_title{
    text-align: left;
    font-weight: bold;
    font-family: inherit;
}

.private-page .form-window .page-login {
    max-width: 680px;
    top: 3rem;
    left: calc(50% - 340px);
    border-radius: 16px;
}

.private-page .panel-search-results{
    background-color: var(--light-bkg);
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 0 0 16px 16px;
}

.private-page .panel-search-results li{
    cursor: pointer;
    font-size: 1.4rem;
    padding: 0.6rem 0.6rem;
    margin: 0.2rem 0.8rem .6rem 0.8rem;
    border-radius: 4px;
}

.private-page .panel-search-results li:hover{
    color: var(--accent-color);
    background-color: rgba(0,0,0,0.05);
}

.inner-form.submit-design{
    clear: both;
    margin-bottom: 2.4rem;
}

.submit-design .submit-design_row{
    display: flex;
    margin-bottom: 3.2rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.submit-design .submit-design_row.flex-column{
    flex-direction: column;
    align-items: baseline;
}

.submit-design .submit-design_row:not(.flex-column) h5{
    max-width: 100%;
    width: 140px;
    line-height: 1.25;
    margin-right: 1.6rem;
}

.submit-design_info-message{
    background-color: var(--secondary-color-200);
    padding: .8rem 1.2rem;
    margin-top: 1.2rem;
    border-radius: 8px;
    display: flex;
    gap: 8px;
}

.submit-design .file-list{
    display: block;
    width: 100%;
    clear: both;
}

.submit-design .file-list ul li{
    display: inline-flex;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--accent-color-200);
    background-color: var(--accent-color-200);
    border-radius: 999px;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    font-size: 1.4rem;
}

.submit-design .file-list ul li span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
}

.submit-design .file-list ul li button{
    width: 2.8rem;
    height: 2.8rem;
    padding: 5px;
    margin-right: 8px;
    background-color: var(--extra-light-bkg);
}

.submit-design .file-list ul li button:hover{
    background-color: var(--accent-color);
}

.submit-design .file-list ul li button:hover img{
    filter: invert(100%);
}

.dropzone{
    background-color: var(--light-bkg);
}

.dropzone:hover{
    background-color: var(--secondary-color-200);
}


/*Date Picker Override*/
.private-page .react-datepicker-popper{
    z-index: 999;
}

.private-page .react-datepicker__day-name{    font-size: 14px; opacity: 0.6;}
.private-page .react-datepicker__day{         font-size: 15px;}
.private-page .react-datepicker__time-name{   font-size: 15px;}

.private-page .react-datepicker__current-month, 
.private-page .react-datepicker-time__header, 
.private-page .react-datepicker-year-header{  font-size: 14px; font-weight: bold; margin-bottom: 8px;}

.private-page .react-datepicker__day,
.private-page .react-datepicker__day-name{
    width: 29px;
    padding: 6px 0;
}

.private-page .react-datepicker__day--selected, 
.private-page .react-datepicker__day--in-selecting-range, 
.private-page .react-datepicker__day--in-range, 
.private-page .react-datepicker__month-text--selected, 
.private-page .react-datepicker__month-text--in-selecting-range, 
.private-page .react-datepicker__month-text--in-range, 
.private-page .react-datepicker__quarter-text--selected, 
.private-page .react-datepicker__quarter-text--in-selecting-range, 
.private-page .react-datepicker__quarter-text--in-range, 
.private-page .react-datepicker__year-text--selected, 
.private-page .react-datepicker__year-text--in-selecting-range, 
.private-page .react-datepicker__year-text--in-range,

.private-page .react-datepicker__day--selected:hover, 
.private-page .react-datepicker__day--in-selecting-range:hover, 
.private-page .react-datepicker__day--in-range:hover, 
.private-page .react-datepicker__month-text--selected:hover, 
.private-page .react-datepicker__month-text--in-selecting-range:hover, 
.private-page .react-datepicker__month-text--in-range:hover, 
.private-page .react-datepicker__quarter-text--selected:hover, 
.private-page .react-datepicker__quarter-text--in-selecting-range:hover, 
.private-page .react-datepicker__quarter-text--in-range:hover, 
.private-page .react-datepicker__year-text--selected:hover, 
.private-page .react-datepicker__year-text--in-selecting-range:hover, 
.private-page .react-datepicker__year-text--in-range:hover{
    border-radius: 99px;
    background-color: var(--accent-color);
    color: #fff;
}

.private-page .react-datepicker-popper button{
    background-color: unset;
}

.private-page .react-datepicker__navigation-icon::before {
    border-color: var(--secondary-color);
}

.private-page .react-datepicker__day--keyboard-selected, 
.private-page .react-datepicker__month-text--keyboard-selected, 
.private-page .react-datepicker__quarter-text--keyboard-selected, 
.private-page .react-datepicker__year-text--keyboard-selected{
    background-color: var(--secondary-color-200)!important;
}

.private-page .react-datepicker__header,
.private-page .react-datepicker__month {
    background-color: var(--extra-light-bkg);
    margin: 0;
    padding: 0.4rem;
}

.private-page .react-datepicker__month-container {
    border-radius: 12px;
    overflow: hidden;
    border: none;
    box-shadow: 0 0 0px 5px #528bc82b;
}

.private-page .react-datepicker__header{
    padding-top: 8px;
    border-bottom: 1px solid #e6e6e6;
}

.private-page .react-datepicker {
    background-color:transparent;
    border: none;
}

.private-page .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom: none;
}

.private-page .react-datepicker__day.react-datepicker__day--outside-month{
    opacity: 0.2;
}

.private-page .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{display: none;}


/*Toast Override*/
.Toastify__toast-container--top-right{
    top: 8px!important;
    right: 8px!important;
}

.Toastify__toast{
    border-radius: 8px!important;
    padding: 8px 12px 8px 16px!important;
}

.Toastify__toast-body {
    padding: 0px!important;
    margin: 0!important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
   background: var(--color-error-500)!important;
}

.Toastify__progress-bar {
    height: 3px!important;
}

.Toastify__close-button > svg {
    height: 20px!important;
    width: 20px!important;
    opacity: 1!important;
    display: none!important;
}

.Toastify__close-button{
    opacity: 0!important;
}

.private-page .tooltip{
    padding: 12px 10px;
    border-radius: 8px;
    background-color: var(--dark-bkg);
    color: #fff;
    font-size: 11px;
    text-transform: lowercase;
    font-weight: 400;
}