/***
** Responsive
***/
@media screen and (max-width: 1280px) {
    main:not(.page-home) .grid.size_6-5,
    main:not(.page-home) .grid.size_5-6 {
        grid-column-gap: 5%;
    }

    .page-about .entry-content h2{
        padding-top: 0;
    }

    .page-home .cucine-living .grid > div{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .page-home .cucine-living p.border-t{
        border: none;
    }

    .page-home .cucine-living h3{
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding-top: 2.4rem;
        padding-bottom: 1.6rem;
    }
}

@media screen and (max-width: 1024px) {
    .grid.size_6-5:not(.row-contact),
    .grid.size_5-6:not(.row-contact),
    .grid.size_6-6 {
        grid-template-columns: 100%;
    }

    .grid.size_6-5 > div,
    .grid.size_5-6 > div {
        margin-bottom: 6.4rem;
    }

    .max-w-site {
        max-width: 860px;
    }

    .space.mb-8 {
        margin-bottom: 4rem;
    }

    .entry-content p{
        max-width: unset;
    }

    .row-contact.mb-7{
        margin-bottom: 4.8rem;
    }

    .row-contact.mb-7:last-child{
        margin-bottom: 0;
    }

    .page-company .entry-content .grid.size_6-6:last-child div.pl-4{
        padding-left: 0;
        margin-top: 2rem;
    }

    .page-about .entry-content > .grid.size_6-6:nth-child(3) > div:last-child,
    .page-about .entry-content > .grid.size_6-6:first-child > div:last-child,
    .page-company .entry-content .grid.size_6-6:last-child div.pl-4{
        order: -1;
    }

    .page-home .grid.size_6-5 > div,
    .page-home .grid.size_5-6 > div,
    .page-home .grid.size_6-5 > div h2,
    .page-home .grid.size_5-6 > div h2{
        margin-bottom: 0rem;
    }

    .page-home .grid.size_5-6 > div.border-t:last-child {
        margin-left: 0;
        border-top: 0;
        padding: 0;
    }

    .image-divider::before{
        padding-top: 56%;
    }

    .page-home .cucine-living .grid div:first-child h3, 
    .page-home .cucine-living .grid div:first-child p,
    .page-home .cucine-living .grid div:last-child h3, 
    .page-home .cucine-living .grid div:last-child p{
        padding-right: 0;
        padding-left: 0;
        text-align: left;
    }

    .page-home .cucine-living .grid div:first-child p,
    .page-home .cucine-living .grid div:last-child p{
        order: 4;
    }

    .cucine-living{
        position: relative;
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
    }

    .cucine-living h3.orange{
        position: absolute;
        top: 0;
        width: 50%;
    }

    .page-home .cucine-living .icon-e {
        position: relative;
        left: calc(50% - 8rem);
        width: 14rem;
        z-index: 1;
        padding-right: 0;
        margin-bottom: 4rem;
    }

    .page-home .cucine-living .grid > div:last-child h3{
        right: 3.2rem;
        text-align: right;
    }

    .page-home .bannerContract .innerBannerContract {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .bannerContract .grid div.bg-gradient{
        order: 2;
    }

    .grid.size_3-3-3-3 {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 4rem;
    }

    .orange.counter {
        padding-top: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
    }
}

@media screen and (max-width: 769px) {
    .cucine-living .p-small {
        font-size: 1.6rem;
    }

    h1, .h1{font-size: 3.2rem;}
    h2, .h2{font-size: 2.7rem; }
    h3, .h3{font-size: 2.2rem;}
    h4, .h4{font-size: 1.8rem;}

    .image-divider::before {
        padding-top: 80%;
    }

    .inner-image-divider > div{
        background-attachment: scroll;
    }

}

@media screen and (max-width: 600px) {
    .px-4 {
        padding-left: 2.2rem;
        padding-right: 2.2rem;
    }

    .row-contact.size_5-6{
        grid-template-columns: 100%;
        grid-row-gap:  1rem;
    }

    .row-contact.grid > *{
        border: none;
        padding-top: 0;
    }

    .page-home .cucine-living .icon-e {
        position: relative;
        left: calc(50% - 5rem);
        width: 10rem;
        z-index: 1;
        padding-right: 0;
        transform: translateY(1rem);
        margin-bottom: 4rem;
    }

    .page-home .bannerContract .h2{
        font-size: 2.4rem;
    }

    .image-divider::before {
        padding-top: 100%;
    }

    .btn.float-r{
        float: unset;
    }

    .grid.size_3-3-3-3 {
        grid-template-columns: 100%;
        grid-row-gap: 4rem;
        text-align: center;
    }

    .orange.counter{
        padding-top: 0;
        border: none;
        margin-bottom: 1rem;
    }

    .page-home .border-t{
        border: none;
        padding-top: 0;
    }

    .cucine-living img{
        margin-bottom: 2rem;
    }

    .swiper-wrapper .swiper-slide{
        max-height: 400px;
        height: 400px;
    }
    
    .swiper-wrapper .swiper-slide span,
    .swiper-wrapper .swiper-slide img{
        height: 400px;
    }

}

@media screen and (max-width: 360px) {
    .cont-above-fold .btn{
        font-size: 1.45rem;
    }

    .page-home .cucine-living .icon-e{
        left: calc(50% - 4rem);
        width: 8rem;
        transform: translateY(1rem);
        margin-bottom: 4rem;
    }
}