.intro-single-page{
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
}

.intro-single-page::before{
  content: '';
  display: block;
  position: absolute;
  background: url(../../img/logo-D-symbol.svg) no-repeat;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  z-index: 0;
  background-size: 40rem;
  background-position: right 4rem;
  opacity: 0.2;
  bottom: 0;
  right: 0;
}

/*** 
  Breadcrumbs
***/
.breadcrumbs{
  display: flex;
  font-size: 1.4rem;
  position: relative;
  z-index: 3;
}

.breadcrumbs a{
  color: #fff;
  text-decoration: underline;
}

.breadcrumbs li:last-child{
  opacity: 0.5;
}

.breadcrumbs li::after{
  content: '•';
  padding: 0 .8rem;
}

.breadcrumbs li:last-child:after{
  content: none;
}

@media screen and (max-width: 1024px) {
  .intro-single-page {
    min-height: 24rem;
  }

  .intro-single-page::before {
    background-size: 32rem;
  }
}

@media screen and (max-width: 769px) {
  .intro-single-page {
    min-height: 18rem;
  }

  .intro-single-page::before {
    background-size: 0;
    background-position: right 0;
  }
}

@media screen and (max-width: 600px) {
  .intro-single-page.mb-8 {
    margin-bottom: 6.4rem;
  }
}