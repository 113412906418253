.bannerContract{
    text-align: center;
}

.bannerContract .grid{
    grid-column-gap: 0;
}

.bannerContract .grid > div{
    height: 100%;
}

.bannerContract .h2{
    line-height: 1.2;
    margin-bottom: 2rem;
}

.bannerContract .btn{
    margin-top: 3rem;
}

.bannerContract .innerBannerContract{
    max-width: 48rem;
    margin: auto;
    padding: 10rem 0;
}

.bannerContract .img-bannerContract{
    height: 100%;
    position: relative;
}

.bannerContract .img-bannerContract > div{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-color: antiquewhite;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}


@media screen and (max-width: 1280px) {
    .bannerContract .innerBannerContract {
        padding: 8rem 2.4rem;
    }
}

@media screen and (max-width: 1024px) {
    .bannerContract .img-bannerContract{
        position: relative;
    }

    .bannerContract .img-bannerContract::before{
        content: '';
        display: block;
        width: 100%;
        height: 40rem
    }

    .bannerContract .grid{
        grid-row-gap: 0px;
    }

    .bannerContract .innerBannerContract {
        padding: 6rem 2.4rem;
    }
}

@media screen and (max-width: 600px) {
    .page-home .bannerContract .innerBannerContract,
    .bannerContract .innerBannerContract {
        padding: 4rem  2.4rem!important;
    }

    .bannerContract .img-bannerContract::before{
        height: 30rem;
    }

    .bannerContract .h2{
        font-size: 2.4rem;
    }

    .bannerContract p:not(.h2){
        font-size: 1.4rem;
    }

    .bannerContract .btn {
        margin-top: 1rem;
    }
}