/***
 * Home
***/
.page-home{
    max-width: 100%;
    overflow-x: hidden;
}

.page-home .bannerContract .h2{
    font-size: 2.8rem;
    line-height: 1.1;
}

.page-home .bannerContract .innerBannerContract{
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.cont-plus{
    min-height: 11rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;
}

.orange.counter{
    margin-bottom: -1rem;
    line-height: 1;
}

.page-home .grid.size_5-6 {
    grid-template-columns: 35% 50%;
}

.page-home .grid.size_5-6 > div:last-child{
    margin-left: 20px;
}

.page-home .above-fold{
    position: relative;
}

.page-home .above-fold > div{
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-color: antiquewhite;
}

.page-home .above-fold .cont-above-fold{
    position: relative;
    z-index: 9;
}

.page-home .above-fold::before,
.page-home .above-fold::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 11rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(0, 0, 0, 0.6) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 2;
}

.page-home .above-fold::after{
    top: unset;
    transform: unset;
    bottom: 0;
    height: 30rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 35%,  rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.75) 100%);
}

.page-home .cucine-living .grid div:first-child h3,
.page-home .cucine-living .grid div:first-child p{
    padding-right: 16rem;
}

.page-home .cucine-living .grid div:last-child h3,
.page-home .cucine-living .grid div:last-child p{
    padding-left: 18rem;
    text-align: right;
}

.page-home .cucine-living{
    position: relative;
}

.page-home .cucine-living .icon-e{
    position: absolute;
    background-color: #fff;
    top: 0;
    left: calc(50% - 8rem);
    width: 16rem;
    height: auto;
    padding: 0 1rem;
    box-sizing: border-box;
}

.page-home + .site-footer{
    margin-top: 0;
}

.page-home .photo-carousel .t-align-center p{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
}

.swiper-wrapper{
    align-items: center;
}

.swiper-wrapper .swiper-slide{
    max-height: 480px;
    overflow: hidden;
    width: auto;
    max-width: 90vw;
    height: 480px;
}

.swiper-wrapper .swiper-slide span,
.swiper-wrapper .swiper-slide img{
    width: 100%;
    height: 480px;
    object-fit: cover;
    object-position: center;
}

.swiper-horizontal{
    position: relative;
}

.swiper-pagination-bullets-dynamic{
    bottom: -24px!important;
}





/***
 * Contacts
***/
.row-contact.grid{
    grid-template-columns: 20rem 1fr;
    grid-column-gap: 2rem;
}

.row-contact.grid > *{
    border-top: 1px solid #ddd;
    padding-top: 1.6rem;
}

.entry-content .row-contact li::before{
    display: none;
}

.entry-content .row-contact li{
    margin-bottom: 1.6rem;
}

.page-contacts .entry-content .size_6-5 div:last-child > div > div > div > div > div > div{
    filter: saturate(.75);
}



/***
 * About
***/
.page-about .entry-content h2{
    padding-top: 3.2rem;
}


/***
 * Privacy
***/
.page-cookies{
    margin: auto;
    padding: 4rem 2rem;
    max-width: 72rem;
}

.page-cookies h1,
.page-cookies h2,
.page-cookies h3,
.page-cookies h4,
.page-cookies h5,
.page-cookies h6{
    font-weight: bold;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
}

.page-cookies a{
    color: var(--orange);
}


/***
 * Trasparenza
***/
.page-info-aiuti-stato{
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 4rem;
}


/***
 * Iubenda
***/
body .iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right]{
    right: unset!important;
    left: 0!important;
}